  // This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "bootstrap";
import "@fortawesome/fontawesome-free/js/all";
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap4-duallistbox";
import dt from "datatables.net";
import "sweetalert2";
import multi from "multi.js/dist/multi.min.js";
import "@nathanvda/cocoon";
document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();
  //$('.toast').toast({ autohide: false })
  //$('#toast').toast('show')
  dt(window, $);
})

Rails.start()
Turbolinks.start()
ActiveStorage.start()
require("packs/custom")
require("packs/sweetalert2")
require("admin-lte");
